var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pl-2 pr-2 pt-2" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "reg-dialog", persistent: "" },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          !_vm.subscribe
            ? _c(
                "v-card",
                { staticClass: "initial-reg", attrs: { dark: "" } },
                [
                  _c("v-card-title", { staticClass: "lighten-2" }, [
                    _c("span", { staticClass: "text-h5" }, [_vm._v("REGISTER")])
                  ]),
                  _c("div", { staticClass: "sub-content" }, [
                    _vm._v(
                      " Would you like to receive notification about updates to "
                    ),
                    _c("span", { staticClass: "brandSpan-tour" }, [
                      _vm._v("timber"),
                      _c("b", [_vm._v("X")])
                    ]),
                    _vm._v(" ? ")
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "reg-card-actions" },
                    [
                      _c(
                        "v-btn",
                        { attrs: { plain: "" }, on: { click: _vm.onClickNo } },
                        [_vm._v(" No ")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              _vm.subscribe = true
                            }
                          }
                        },
                        [_vm._v(" Yes ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-expand-transition",
            [
              _vm.subscribe
                ? _c(
                    "v-card",
                    {
                      staticClass:
                        "mx-auto transition-fast-in-fast-out v-card--reveal reg-card",
                      attrs: { dark: "", loading: _vm.loading }
                    },
                    [
                      _c("v-card-title", { staticClass: "lighten-2" }, [
                        _c("span", { staticClass: "text-h5" }, [
                          _vm._v("REGISTER")
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "reg-content" },
                        [
                          _c("span", { staticClass: "pl-1 subtitle" }, [
                            _vm._v("Please provide your details below:")
                          ]),
                          _c(
                            "v-form",
                            {
                              ref: "regForm",
                              staticClass: "pa-4",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-2",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            ref: "emailField",
                                            attrs: {
                                              rules: _vm.emailRules,
                                              label: "E-mail",
                                              dense: "",
                                              "prepend-icon":
                                                "mdi-email-outline",
                                              placeholder: "johndoe@email.com",
                                              "hide-details": "auto",
                                              loading: _vm.checkingEmail,
                                              "error-messages": _vm.emailErrors,
                                              required: ""
                                            },
                                            on: { change: _vm.onChangeEmail },
                                            model: {
                                              value: _vm.email,
                                              callback: function($$v) {
                                                _vm.email = $$v
                                              },
                                              expression: "email"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-2 pl-11",
                                          attrs: { cols: "12", md: "6" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              rules: [
                                                function(v) {
                                                  return (
                                                    (!!v && v.length > 0) ||
                                                    "First Name is required"
                                                  )
                                                }
                                              ],
                                              label: "First Name",
                                              "hide-details": "auto",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.firstName,
                                              callback: function($$v) {
                                                _vm.firstName =
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                              },
                                              expression: "firstName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-2 pl-11",
                                          attrs: { cols: "12", md: "6" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              rules: [
                                                function(v) {
                                                  return (
                                                    (!!v && v.length > 0) ||
                                                    "Last Name is required"
                                                  )
                                                }
                                              ],
                                              label: "Last Name",
                                              "hide-details": "auto",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.lastName,
                                              callback: function($$v) {
                                                _vm.lastName =
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                              },
                                              expression: "lastName"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-2 pl-11",
                                          attrs: { cols: "12", md: "6" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              rules: [
                                                function(v) {
                                                  return (
                                                    (!!v && v.length > 0) ||
                                                    "Company is required"
                                                  )
                                                }
                                              ],
                                              label: "Company",
                                              "hide-details": "auto",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.company,
                                              callback: function($$v) {
                                                _vm.company =
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                              },
                                              expression: "company"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-2 pl-11",
                                          attrs: { cols: "12", md: "6" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              label: "Primary Profession",
                                              "hide-details": "auto"
                                            },
                                            model: {
                                              value: _vm.title,
                                              callback: function($$v) {
                                                _vm.title =
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                              },
                                              expression: "title"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-2 pl-11",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              rules: [
                                                function(v) {
                                                  return (
                                                    (!!v && v.length > 0) ||
                                                    "Country is required"
                                                  )
                                                }
                                              ],
                                              label: "Country",
                                              "hide-details": "auto",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.country,
                                              callback: function($$v) {
                                                _vm.country =
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                              },
                                              expression: "country"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-2",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              label: "Feedback",
                                              dense: "",
                                              hint:
                                                "Provide feedback or ask a question about timberX. We will get back to you in 3 business days.",
                                              "persistent-hint": "",
                                              "auto-grow": "",
                                              outlined: "",
                                              "hide-details": "auto",
                                              "prepend-icon":
                                                "mdi-comment-text-outline",
                                              rows: "3"
                                            },
                                            model: {
                                              value: _vm.feedback,
                                              callback: function($$v) {
                                                _vm.feedback =
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                              },
                                              expression: "feedback"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-2 pb-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              rules: [
                                                function(v) {
                                                  return (
                                                    !!v ||
                                                    "Please consent to continue"
                                                  )
                                                }
                                              ],
                                              dense: "",
                                              "hide-details": "auto",
                                              required: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "consentLabel"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " I consent to receiving email updates about the timberX tool in accordance with the "
                                                          ),
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href:
                                                                  "" +
                                                                  _vm.policyLink,
                                                                target: "_blank"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Privacy Policy"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(". ")
                                                        ]
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              false,
                                              4050230120
                                            ),
                                            model: {
                                              value: _vm.consent,
                                              callback: function($$v) {
                                                _vm.consent = $$v
                                              },
                                              expression: "consent"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "consentLabel" },
                                            [
                                              _vm._v(
                                                " For more information on building with mass timber, please visit "
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://www.naturallywood.com/",
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "www.naturallywood.com"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" or "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "mailto:timberX@wsp.com?subject=timberX",
                                                    target: "_blank"
                                                  }
                                                },
                                                [_vm._v("contact WSP")]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        { staticClass: "reg-card-actions" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { plain: "" },
                              on: {
                                click: function($event) {
                                  _vm.show = false
                                }
                              }
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { loading: _vm.loading, outlined: "" },
                              on: { click: _vm.onRegister }
                            },
                            [_vm._v(" Submit ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }