


















































































































































































import Vue from "vue";
var _self = this;
export default {
  name: "Registration",
  props: {
    value: Boolean,
  },
  data() {
      return {
          emailErrors: null,
        loading: false,
        checkingEmail: false,
      subscribe: 0,
      valid: true,
      firstName: "",
      lastName: "",
      title: "",
      company: "",
      country: "",
      feedback: "",      
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length > 2) || "Name must be greater than 2 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      consent: false,
    };
  },
  created() {},
  async mounted() {
    if (
      this.$store.state.misc.userCountry &&
      this.$store.state.misc.userCountry.length > 0
    ) {
      this.country = this.$store.state.misc.userCountry;
    }
  },
  computed: {
    contactLink() {
      return `${this.$timberURL}/contact`;
    },
    policyLink() {
      return `${this.$timberURL}/privacy-policy`;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
        methods: {
            resetErrors() {
                this.isEmailAvailable = true
            },
    onClickNo() {
      this.$store.dispatch("misc/setRegistrationStore", { val: "0" });
      this.show = false;
    },
      async checkEmail() {                
        this.checkingEmail = true;
        var user = await this.$store.dispatch("datafields/getUser", [this.email, '0']);
        this.checkingEmail = false;
        return (user && user.data && user.data.length > 0);
      },
      onChangeEmail() {
          if (this.$refs.emailField.hasError && !this.emailErrors) {             
            return;
          }
          var _t = this;
          this.checkEmail().then(emailExists => {              
              this.emailErrors = emailExists ? ['This email is already registered'] : null;       
          });
          
      },
    async onRegister() {
        if (!this.$refs.regForm.validate() || (this.emailErrors && this.emailErrors.length > 0)) {
        return;
      }
      this.loading = true;            
        this.$store.dispatch("datafields/userContactInfo", [
        this.firstName,
        this.lastName,
        this.email,
        this.title,
        '',
        this.$store.state.misc.userIP,
        this.company,
        this.country,
        this.feedback,
        '',
        '',
        ''
      ]);      
      this.$store.dispatch("misc/setRegistrationStore", { val: "1" });      
      this.$emit('SubmitReg'); 
      this.loading = false;           
      this.show = false;
    },
  },
};
